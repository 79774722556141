import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import FilterLayout from "../components/filter/filter-layout"
import FilterSearch from "../components/filter/filter-search"
import { eventSearch } from "../services/gtm-events"

const Search = ({
  data: {
    datoCmsSearchPage,
    regionChildren,
    regionParents,
    allPortalWine,
    allPortalGrape,
    allPortalTag,
    allPortalPairing,
  },
  pageContext: { productContext },
}) => {
  const productData = {
    allWines: allPortalWine.nodes,
    allGrapeTypes: allPortalGrape.nodes,
    allWineTags: allPortalTag.nodes,
    allPairingTypes: allPortalPairing.nodes,
    allWineTypes: productContext.allWineTypes,
  }
  const breadcrumbs = [{ title: "Search", id: datoCmsSearchPage.id }]
  const [searchValue, setSearchValue] = useState("")

  const handleSearchValue = (value) => {
    setSearchValue(value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length < 3) {
        return
      }
      eventSearch(searchValue)
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

  return (
    <Layout>
      <div>
        <Intro
          title={datoCmsSearchPage.title}
          description={datoCmsSearchPage.description}
          breadcrumbs={breadcrumbs}
        />
        <FilterSearch
          placeholder={datoCmsSearchPage.searchPlaceholder}
          heading={datoCmsSearchPage.searchHeading}
          handleSearchValue={handleSearchValue}
        />
        <FilterLayout
          productContext={productData}
          searchValue={searchValue}
          regionChildren={regionChildren.nodes}
          regionParents={regionParents.nodes}
        />
      </div>
    </Layout>
  )
}

export default Search

export const query = graphql`
  {
    datoCmsSearchPage {
      title
      description
      id: originalId
      searchPlaceholder
      searchHeading
    }
    regionChildren: allDatoCmsRegionChild {
      nodes {
        id: portalRegionId
        title
        parentRegion {
          id: originalId
        }
      }
    }
    regionParents: allDatoCmsRegionParent(sort: { fields: position }) {
      nodes {
        id: originalId
        title
      }
    }
    allPortalWine {
      nodes {
        ...AllPortalWine
      }
    }
    allPortalGrape {
      nodes {
        ...AllPortalGrape
      }
    }
    allPortalTag {
      nodes {
        ...AllPortalTag
      }
    }
    allPortalPairing {
      nodes {
        ...AllPortalPairing
      }
    }
  }
`
