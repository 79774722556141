import React, { useState } from "react"
import Container from "../container/container"
import style from "./filter-search.mod.scss"

const FilterSearch = ({ placeholder, heading, handleSearchValue }) => {
  return (
    <Container gutters pt={2} width={"large"}>
      <div className={style.search}>
        <p>{heading}</p>
        <input
          autoFocus
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            handleSearchValue(event.target.value)
          }}
        />
      </div>
    </Container>
  )
}

export default FilterSearch
